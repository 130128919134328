:root {
  --main-bg: rgb(22, 22, 26);
  --claim-grad: linear-gradient(173deg, #F7C93F 9%, #C68413 92%);
  --item-bg: rgba(0, 0, 0, 0.25);
  --banner-bg: rgb(255, 255, 255);
}
:nth-child(1) { --nth-child: 1 }
:nth-child(2) { --nth-child: 2 }
:nth-child(3) { --nth-child: 3 }
:nth-child(4) { --nth-child: 4 }
:nth-child(5) { --nth-child: 5 }
:nth-child(6) { --nth-child: 6 }
:nth-child(7) { --nth-child: 7 }
:nth-child(8) { --nth-child: 8 }
:nth-child(9) { --nth-child: 9 }

.loading {
  align-items: center;
  background-color: var(--main-bg);
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}
.loading__text {
  color: white;
  font-size: 22px;
}
.app {
  background-color: black;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}
.gallery {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  height: 84vh;
  position: relative;
}
.gallery__item {
  background-color: black;
  height: calc(50% - 2px);
  margin: 1px;
  position: relative;
  width: calc(33.33% - 2px);
}

.gallery__item_1x3{
  width: calc(33.33% - 2px);
  height: calc(100% - 2px);
}

.gallery__item_2x3{
  width: calc(33.33% - 2px);
  height: calc(50% - 2px);
}

.gallery__item_3x3{
  width: calc(33.33% - 2px);
  height: calc(33.33% - 2px);
}

.gallery__image.out {
  animation: fade-out 0.33s calc(var(--nth-child) * 0.05s) forwards;
}
.gallery__image.in {
  animation: fade-in 0.35s calc(var(--nth-child) * 0.05s) forwards;
  opacity: 0;
}

.gallery__overlay {
  position: absolute;
  z-index: 0;
  opacity: .25;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.gallery__overlay-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(4px);
  transform: scale(1.1);
}
.gallery__figure {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, .25) 40%); 
  display: flex;
  flex: 1 1;
  justify-content: center;
  height: 100%;
  margin: 0;
  width: 100%;
  position: relative;
  z-index: 1;
}
.gallery__img {
  object-fit: contain;
  width: 100%;
  max-height: 100%; 
}
.gallery__item.gallery__cta {
  position: absolute;
  bottom: 0;
  right: 0;
}
@media screen and (orientation: portrait) {
  .gallery__item_1x3{
    width: calc(100vw - 2px);
    height: calc(28vh - 2px);
  }

  .gallery__item_2x3{
    width: calc(50vw - 2px);
    height: calc(28vh - 2px);
  }

  .gallery__item_3x3{
    width: calc(50vw - 2px);
    height: calc(14vh - 2px);
  }
  .gallery__item_3x3:last-child
  {
    height: calc(28vh - 2px);
    width: calc(100vw - 2px);
  }

}
/* CTA */
.cta {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 16px;
}
.cta__title {
  margin: 4px 0;
  color: white;
  font-size: 3.5vmin;
}
.cta__instructions {
  color: white;
  font-size: 2.5vmin;
  margin: 0;
}
.cta__qr {
  background: white;
  padding: 2vmin;
  margin: 1.5vmin 0;
  border-radius: 2vmin;
  position: relative;
}
.cta__qr::after {
  background-image: url('/public/arrow.svg');
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: block;
  height: 11vmin;
  opacity: 0.6;
  position: absolute;
  right: -8vmin;
  top: calc(50% - 10vmin);
  width: 7vmin;
}
.cta__qr-img {
  width: 15vmin;
  height: auto;
}
@media screen and (orientation: portrait) {
  .cta__title {
    margin: 0;
    font-size: 3vmax
  }
  .cta__instructions {
    font-size: 2vmax;
  }
  .cta__qr {
    margin: 1.25vmax 0;
  }
}
/* Banner */
.banner {
  background: white;
  display: flex;
  height: 16vh;
  flex-direction: row;
  justify-content: space-evenly;
  position: relative;
  z-index: 2;
}
.banner__offer {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 8px 2vmin;
}
.banner__claim {
  align-items: center;
  background: var(--claim-grad);
  border-radius: 50px;
  box-shadow: 0 0 0 1px rgba(255,196,22,0.37), 0 4px 12px 0 rgba(250,171,60,0.45);
  box-sizing: border-box;
  display: flex;
  height: 10vmin;
  padding: 1vmin;
  margin: 0 12px;
  text-align: center;
  width: 10vmin;
}
.banner__claim span {
  color: white;
  font-size: 2vmin;
  font-weight: bold;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0,0,0,0.19);
  text-transform: uppercase;
}
.banner__text {
  font-size: 3.25vmin;
  font-weight: bold;
  margin: 0;
}
.banner_custom_text {
  font-size: 4.25vmin;
  font-weight: bold;
  margin: 0;
}
.banner__unit {
  color: green;
  display: block;
  font-size: 2.3vmin;
  font-weight: bold;
  margin: 0.2vmin 0;
}
.banner__notice-text {
  display: block;
  font-size: 1.75vmin;
  opacity: .6;
}
@keyframes fade-out {
  from { opacity: 1 }
  to { opacity: 0 }
}
@keyframes fade-in {
  from { opacity: 0 }
  to { opacity: 1 }
}
@keyframes bg-out {
  from { 
    background-color: var(--main-bg);
    opacity: 1;
  }
  to { 
    background-color: black;
    opacity: 0.66 
  }
}
@keyframes bg-in {
  from { 
    background-color: black;
    opacity: 0.66 
  }
  to { 
    background-color: var(--main-bg);
    opacity: 1;
  }
}